import jsPDF from "jspdf";
import i18n from "../i18n";
import FileSaver from "file-saver";
import domtoimage from "dom-to-image";
import XLSX from "xlsx";

import "./GT-Walsheim-Pro-Bold-normal.js";
import "./GT-Walsheim-Pro-Regular-normal.js";
import moment from "moment";
import autoTable from "jspdf-autotable";

export async function exportStatisticsAsPDF(
  title,
  caseCount,
  numberOfWhistleblowers,
  numberOfWhistleblowersWithCases,
  newCases,
  acceptedCases,
  inProgressCases,
  closedCases,
  clientCaseAverageTime
) {
  // Set initial JSPDF details and title
  const doc = new jsPDF("p", "mm", "a4");
  doc.setProperties({
    title: title,
  });
  doc.setFontSize(12);
  doc.setFont("GT-Walsheim-Pro-Bold", "normal");
  doc.text(title, 105, 20, { align: "center" });

  // Set yOffset to prevent text overlaps
  let yOffset = 30;
  let currentDate = moment().format("DD.MM.YYYY");

  // Write down export date
  doc.setFont("GT-Walsheim-Pro-Bold", "normal");
  doc.setFontSize(10);
  if (yOffset + 10 > doc.internal.pageSize.getHeight() - 20) {
    doc.addPage();
    yOffset = 30;
  }
  doc.text(`${i18n.t(`statistics.exportedOn`)}: ${currentDate}`, 10, yOffset);
  yOffset += 10;

  // Call generateCaseGraphsAndCategories to render information regarding
  // total number of cases; keep track of yOffset to
  // prevent succeeding texts from overlapping
  // Do not render anything if there's no total
  if (caseCount.total !== 0) {
    yOffset = await generateCaseGraphsAndCategories(
      doc,
      yOffset,
      `${i18n.t(`statistics.totalNumberOfCases`)}`,
      caseCount,
      "totalCasePieChart"
    );
  }

  // Write down total number of whistleblowers and
  // whistleblowercases
  doc.setFont("GT-Walsheim-Pro-Bold", "normal");
  doc.setFontSize(10);
  if (yOffset + 20 > doc.internal.pageSize.getHeight() - 20) {
    doc.addPage();
    yOffset = 30;
  }
  doc.text(
    `${i18n.t(
      `statistics.totalNumberOfWhistleblowers`
    )}: ${numberOfWhistleblowers}`,
    10,
    yOffset
  );
  yOffset += 10;

  if (yOffset + 10 > doc.internal.pageSize.getHeight() - 20) {
    doc.addPage();
    yOffset = 30;
  }
  doc.text(
    `${i18n.t(
      `statistics.totalNumberOfWhistleblowersWithCases`
    )}: ${numberOfWhistleblowersWithCases}`,
    10,
    yOffset
  );

  // Call generateCaseGraphsAndCategories to render information regarding
  // new, accepted, in progress, and clsoed cases; keep track of yOffset to
  // prevent succeeding texts from overlapping
  // Do not render anything if there's no total
  if (newCases.total !== 0) {
    doc.addPage(); // Add new page before rendering next set of graphs and data
    yOffset = 20;
    yOffset = await generateCaseGraphsAndCategories(
      doc,
      yOffset,
      `${i18n.t(`statistics.totalNumberOfNewCases`)}`,
      newCases,
      "newCasePieChart"
    );
  }
  if (acceptedCases.total !== 0) {
    doc.addPage(); // Add new page before rendering next set of graphs and data
    yOffset = 20;
    yOffset = await generateCaseGraphsAndCategories(
      doc,
      yOffset,
      `${i18n.t(`statistics.totalNumberOfAcceptedCases`)}`,
      acceptedCases,
      "acceptedCasePieChart"
    );
  }
  if (inProgressCases.total !== 0) {
    doc.addPage(); // Add new page before rendering next set of graphs and data
    yOffset = 20;
    yOffset = await generateCaseGraphsAndCategories(
      doc,
      yOffset,
      `${i18n.t(`statistics.totalNumberOfInProgressCases`)}`,
      inProgressCases,
      "inProgressCasePieChart"
    );
  }
  if (closedCases.total !== 0) {
    doc.addPage(); // Add new page before rendering next set of graphs and data
    yOffset = 20;
    yOffset = await generateCaseGraphsAndCategories(
      doc,
      yOffset,
      `${i18n.t(`statistics.totalNumberOfClosedCases`)}`,
      closedCases,
      "closedCasePieChart"
    );
    // Write down Average duration in days until a case is closed
    doc.setFont("GT-Walsheim-Pro-Bold", "normal");
    doc.setFontSize(10);
    if (yOffset + 10 > doc.internal.pageSize.getHeight() - 20) {
      doc.addPage();
      yOffset = 30;
    }
    doc.text(
      `${i18n.t(`statistics.averageDurationUntil`)}: ${Math.round(
        clientCaseAverageTime
      )}`,
      10,
      yOffset
    );
  }

  addFooter(doc, title);
  FileSaver(doc.output("bloburl"), title);
}

async function generateCaseGraphsAndCategories(
  doc,
  yOffset,
  caseType,
  caseData,
  elementId
) {
  // Write down the total number of cases
  doc.setFont("GT-Walsheim-Pro-Bold", "normal");
  doc.setFontSize(10);
  doc.text(`${caseType}: ${caseData.total}`, 10, yOffset);
  yOffset += 10;

  // Retrieve graph from frontend using elementId.
  const element = document.getElementById(elementId);
  // Use dom-to-image to convert the element to a PNG data URL
  const imgData = await domtoimage.toPng(element);

  // Determine PDF width and height to ensure image fits
  const imgProps = doc.getImageProperties(imgData);
  let pdfWidth = doc.internal.pageSize.getWidth();
  let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  // Define margins
  const margin = 10;
  const maxWidth = pdfWidth - 2 * margin;
  const maxHeight = doc.internal.pageSize.getHeight() - 2 * margin;

  // Adjust image size if it's too large
  if (pdfWidth > maxWidth) {
    const ratio = imgProps.width / imgProps.height;
    pdfWidth = maxWidth;
    pdfHeight = pdfWidth / ratio;
  }

  // Leave space in the PDF for the footer before adding the image
  if (yOffset + pdfHeight > maxHeight) {
    doc.addPage();
    yOffset = margin;
  }
  doc.addImage(imgData, "PNG", margin, yOffset, pdfWidth, pdfHeight);
  yOffset += pdfHeight + margin;

  // Add a spacer between image and table
  yOffset += 5;

  // Prepare table data
  const tableData = prepareTableData(caseData);

  // Calculate dynamic header
  const longestRow = Math.max(...tableData.map((row) => row.length));
  const columns = Array.from({ length: longestRow }, (_, i) =>
    i % 2 === 1 ? i18n.t("statistics.total") : i18n.t("statistics.category")
  );

  // Add table to PDF
  autoTable(doc, {
    startY: yOffset,
    head: [columns],
    body: tableData,
    theme: "grid",
    styles: {
      font: "GT-Walsheim-Pro-Bold",
      fontSize: 10,
      cellPadding: 2,
    },
    columnStyles: {
      0: { fontStyle: "normal" }, // Category column
      1: { fontStyle: "normal" }, // Total column
    },
    headStyles: {
      fillColor: [102, 0, 153], // Purple header color
      textColor: [255, 255, 255], // White text color for header
      fontStyle: "bold",
    },
    margin: { top: 10 },
  });

  // Calculate total table height
  const tableHeight = doc.previousAutoTable.finalY - yOffset;

  // Update yOffset
  yOffset += tableHeight + 10;

  return yOffset;
}

function prepareTableData(caseData) {
  // Convert object to array excluding the key "total"
  const caseDataArray = Object.entries(caseData)
    .filter(([key]) => key !== "total")
    .map(([key, value]) => ({ key, value }));

  // Prepare the output array
  const outputArray = [];

  // Distribute the key-value pairs into groups of at most six
  for (let i = 0; i < caseDataArray.length; i += 4) {
    outputArray.push(
      caseDataArray
        .slice(i, i + 4)
        .flatMap((item) => [
          i18n.t("caseGroup." + item.key.toUpperCase()),
          item.value,
        ])
    );
  }

  return outputArray;
}

async function addFooter(doc, title) {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    // Draw a purple rectangle as footer
    doc.setFillColor(102, 0, 153);
    doc.rect(
      0,
      doc.internal.pageSize.getHeight() - 12,
      doc.internal.pageSize.getWidth(),
      20,
      "F"
    );

    // Add the title to the left of the footer
    doc.setTextColor(255, 255, 255);
    doc.text(title, 10, doc.internal.pageSize.getHeight() - 5);

    // Add the page number to the right of the footer
    doc.text(
      `${i}`,
      doc.internal.pageSize.getWidth() - 20,
      doc.internal.pageSize.getHeight() - 5
    );
  }
}

export async function exportStatisticsAsExcel(
  title,
  caseCount,
  numberOfWhistleblowers,
  numberOfWhistleblowersWithCases,
  newCases,
  acceptedCases,
  inProgressCases,
  closedCases,
  clientCaseAverageTime
) {
  var workBook = XLSX.utils.book_new();
  workBook.Props = {
    Title: title,
  };

  // create sheet
  workBook.SheetNames.push("Sheet1");

  let data = [
    [`${title}`, `${"Total"}`, `${"Additional Data"}`],
    [
      `${i18n.t(`statistics.totalNumberOfCases`)}`,
      `${caseCount.total}`,
      prepareAdditionalDataKey(caseCount),
      prepareAdditionalData(caseCount),
    ],
    [
      `${i18n.t(`statistics.totalNumberOfWhistleblowers`)}`,
      `${numberOfWhistleblowers}`,
    ],
    [
      `${i18n.t(`statistics.totalNumberOfWhistleblowersWithCases`)}`,
      `${numberOfWhistleblowersWithCases}`,
    ],
    [
      `${i18n.t(`statistics.totalNumberOfNewCases`)}`,
      `${newCases.total}`,
      prepareAdditionalDataKey(newCases),
      prepareAdditionalData(newCases),
    ],
    [
      `${i18n.t(`statistics.totalNumberOfAcceptedCases`)}`,
      `${acceptedCases.total}`,
      prepareAdditionalDataKey(acceptedCases),
      prepareAdditionalData(acceptedCases),
    ],
    [
      `${i18n.t(`statistics.totalNumberOfInProgressCases`)}`,
      `${inProgressCases.total}`,
      prepareAdditionalDataKey(inProgressCases),
      prepareAdditionalData(inProgressCases),
    ],
    [
      `${i18n.t(`statistics.totalNumberOfClosedCases`)}`,
      `${closedCases.total}`,
      prepareAdditionalDataKey(closedCases),
      prepareAdditionalData(closedCases),
    ],
    [
      `${i18n.t(`statistics.averageDurationUntil`)}`,
      `${clientCaseAverageTime}`,
    ],
  ];

  var ws = XLSX.utils.aoa_to_sheet(data);

  // put data inside the sheet
  workBook.Sheets["Sheet1"] = ws;

  // set width of column
  var wscols = [{ wch: 50 }, { wch: 50 }];
  ws["!cols"] = wscols;

  //prepare for output
  var workBookOut = XLSX.write(workBook, {
    bookType: "xlsx",
    type: "binary",
    bookSST: true,
  });

  FileSaver(new Blob([stringToArrayBuffer(workBookOut)]), title + ".xlsx");
}

function prepareAdditionalDataKey(statistic) {
  let stringKey = "";

  for (let key of Object.keys(statistic)) {
    if (`${key}` === "total") {
      continue;
    }
    stringKey += `${key}`;
  }
  return stringKey;
}

function prepareAdditionalData(statistic) {
  let stringStatistic = "";

  for (let key of Object.keys(statistic)) {
    if (`${key}` !== "total") {
      continue;
    }

    stringStatistic += `${statistic[key]} \r\n`;
  }
  return stringStatistic;
}

function stringToArrayBuffer(s) {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
}
